import React, { useState, useEffect } from "react";
import { isAfter } from "date-fns";

import Layout from "components/layout/layoutPanel";
import Panel from "components/common/Panel";
import TileCard from "components/common/TileCard";
import SEO from "components/Seo";

import panelData from "constants/homepage/panel";
import tileCardData from "constants/homepage/tile-card";
import HomepageHeroBanner from "components/cards/HomepageHeroBanner";
import AwardsInfo from "components/cards/AwardsInfo";
import NgRacPromoInfo from "components/cards/NgRacPromoInfo";

function Homepage() {
  const [isPromoRac50Active, setIsPromoRac50Active] = useState(true);
  
  useEffect(() => {

    const currentDate = new Date();

    // racbonus 50
    const promoRac50Start = process.env.RACBONUS50_START.split('-');
    const promoRac50End = process.env.RACBONUS50_EXPIRE.split('-');
        
    const promoRac50StartDate = new Date(promoRac50Start[0], promoRac50Start[1]-1, promoRac50Start[2]);
    const promoRac50EndDate = new Date(promoRac50End[0], promoRac50End[1]-1, promoRac50End[2], promoRac50End[3] ? promoRac50End[3] : '', promoRac50End[4] ? promoRac50End[4] : '');

    const isPromoRac50Started = isAfter(currentDate, promoRac50StartDate);
    const isPromoRac50Ended = isAfter(currentDate, promoRac50EndDate);

    if (!isPromoRac50Started || isPromoRac50Ended) {
        setIsPromoRac50Active(false);
    }   
  }, []);

  return (
    <>
      <SEO  title="Kleenheat"
            description="We're WA & the NT's local gas supplier, with over 65 years experience. Enjoy award-winning, local service and great ongoing value with Kleenheat." />

      <HomepageHeroBanner />

      <Layout
        background="mer-bg--gs-grey-lighter"
        border="mer-border--bottom"
        padding="mer-py-sm"
      >
        <div className="container">
          <div className="row justify-content-center">
            <TileCard data={tileCardData} />
          </div>
        </div>
      </Layout>

      <Layout
        background="mer-bg--ui-light"
        padding="mer-pt-lg"
        id="moving-house"
      >
        <div className="container">
          <Panel data={panelData} id="homepage1" />
        </div>
      </Layout>

      <Layout
        background="mer-bg--ui-light"
        padding="mer-py-lg"
        id="sustainability"
      >
        <div className="container">
          <Panel data={panelData} id="homepage2" />
        </div>
      </Layout>

      <Layout background="mer-bg--ui-light" padding="mer-pb-lg" id="supporting">
        <div className="container">
          <Panel data={panelData} id="homepage3" />
        </div>
      </Layout>

      <NgRacPromoInfo
          theme={`mer-theme--cyan${isPromoRac50Active ? ' d-block': ' d-none'}`}
          background="mer-bg--primary-cyan"
          padding="mer-py-lg" />

      <AwardsInfo
        panelBackground="mer-bg--gs-grey-lighter"
        panelPadding="mer-pt-de mer-pb-lg"
      />

      <Layout
        theme="mer-theme--dark"
        background="mer-bg--primary-royal"
        padding="mer-py-lg"
        id="energy-behind-local-business"
      >
        <div className="container">
          <Panel data={panelData} id="homepage4" />
        </div>
      </Layout>
    </>
  );
}

export default Homepage;
